(function ($, generic) {
  /**
   * Event listener to display the waitlist overlay.
   * Event trigger comes from module product_waitlist_v1.
   */
  $(document).on('product.waitlist.overlay', function (event, data) {
    Drupal.behaviors.productWaitlistOverlayV1.launch(data);
  });

  Drupal.behaviors.productWaitlistOverlayV1 = {
    /**
     * Drupal behavior method to handle the waitlist overlay display and functionality.
     * After the 'product.waitlist.overlay' event is fired, the product's data is passed to the launch method.
     * The product_waitlist_overlay inline template is then rendered and displayed using generic.overlay.launch.
     * Once rendered, the waitlist form submit rpc is initialized.
     * Form submission either results in a success message or errors displaying inline.
     * All messaging copy is handled within the waitlist module in the CMS.
     */
    launch: function (data) {
      var that = this;
      var rendered = site.template.get({
        name: 'product_waitlist_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        cssStyle: {
          // Custom styles here.
          width: '538px',
          height: '420px'
        },
        onComplete: function (response) {
          var $overlayCont = $('.js-waitlist-container');
          var $formCont = $('.js-waitlist-form-container', $overlayCont);
          var $form = $('.js-waitlist-form', $overlayCont);
          var $submit = $('.js-waitlist-submit', $overlayCont);
          var $email = $('.js-waitlist-email', $overlayCont);
          var $requestType = $('.js-waitlist-request-type', $overlayCont);
          var $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
          var $successCont = $('.js-waitlist-success', $overlayCont);
          var $errorList = $('.js-error-messages', $overlayCont);
          var $headerCont = $('.js-waitlist-header', $overlayCont);
          var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
          var bPlargeUp = parseInt(Unison.fetch.all()['landscape'], 10);

          $.colorbox.resize();

          // Update the Waitlist Overlay Header base on the INVENTORY_STATUS state
          var headerText =
            data.INVENTORY_STATUS == 2
              ? site.translations.product.temp_out_of_stock_msg
              : data.INVENTORY_STATUS == 3
                ? site.translations.product.coming_soon_stock_msg
                : data.INVENTORY_STATUS == 7
                  ? site.translations.product.sold_out_stock_msg
                  : '';

          $headerCont.text(headerText);

          // Pre filling the loggedin user email for notify me popup content
          var waitlist_populate_signedin_email = Drupal.settings.common.waitlist_populate_signedin_email || false;

          if (waitlist_populate_signedin_email && site.userInfoCookie.getValue('signed_in') == 1) {
            generic.jsonrpc.fetch({
              method: 'user.fullData',
              params: [{}],
              onSuccess: function (result) {
                var dataObj = result.getValue();
                var emailValue = dataObj.email_address ? dataObj.email_address : '';

                $email.val(emailValue);
                $email.focus();
              }
            });
          }

          // Set request type.
          var requestType = data.INVENTORY_STATUS == that.statusIds.inventory.comingSoon ? 'CS' : 'BIS'; // Default is BIS due to TOS for other locales

          $requestType.val(requestType);
          // Set sku_base_id in.
          $skuBaseId.val(data.SKU_BASE_ID);

          Drupal.behaviors.adaptivePlaceholders.setupDOM($email);

          $form.on('submit', function (e) {
            e.preventDefault();

            var pObj = {};
            var params = $(this).serializeArray();
            /**
             * Handle the email_promotions checkbox - if its checked its being read as 'on' when serialized
             * we need a boolean value
             */
            var $pc_email_promotion = $overlayCont.find('input[name="PC_EMAIL_PROMOTIONS"]');

            for (var i = 0, len = params.length; i < len; i++) {
              if (
                $pc_email_promotion.prop('checked') &&
                (params[i].name === 'PC_EMAIL_PROMOTIONS' || params[i].name === 'ACCEPTED_PRIVACY_POLICY')
              ) {
                params[i].value = '1';
              }
              pObj[params[i].name] = params[i].value;
            }
            generic.jsonrpc.fetch({
              method: 'form.get',
              params: [pObj],
              onSuccess: function () {
                $formCont.hide();
                $successCont.show();
              },
              onFailure: function (r) {
                var errorObj = r.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
                if (currentBpWidth >= bPlargeUp) {
                  $overlayCont.closest('#colorbox').colorbox.resize();
                }
              }
            });
          });
        }
      });
    },

    // Status ids used by the waitlist overlay; adjust accordingly.
    statusIds: {
      inventory: {
        comingSoon: 3
      }
    }
  };
})(jQuery, generic);
